import React from "react";
import { useNavigate } from "react-router";
import { useCreateFeeSchedule } from "api/core/feeScheduleHooks";
import constructFeeScheduleFormPayload from "./utils/constructFeeScheduleFormPayload";
import FeeScheduleForm from "../../components/Forms/FeeScheduleForm";

const CreateFeeSchedule = () => {
  const { createRecord: createFeeSchedule } = useCreateFeeSchedule();
  const navigate = useNavigate();

  const handleSubmit = async (feeSchedule) => {
    const payload = constructFeeScheduleFormPayload(feeSchedule);
    try {
      await createFeeSchedule(payload);
      navigate("/fee-schedules");
    } catch (error) {
      console.error("Error creating fee schedule: ", error);
    }
  };

  return <FeeScheduleForm onSubmit={handleSubmit} />;
};

export default CreateFeeSchedule;
