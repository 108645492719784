import React from "react";
import { useNavigate, useParams } from "react-router";
import {
  useUpdateFeeSchedule,
  useFindFeeSchedule,
} from "api/core/feeScheduleHooks";
import constructFeeScheduleFormPayload from "../utils/constructFeeScheduleFormPayload";
import constructFeeScheduleInitialFormValues from "./utils/constructFeeScheduleInitialFormValues";
import FeeScheduleForm from "components/Forms/FeeScheduleForm";
import Spinner from "components/Spinner/Spinner";

const ErrorMessage = ({ navigate }) => {
  return (
    <div data-testid="fee-schedule-edit-error" className="text-center pt-11">
      <div className="text-center">Error Fetching Fee Schedule</div>
      <span
        className="text-action-blue cursor-pointer text-base"
        onClick={() => navigate("/fee-schedules")}
      >
        Go Back to Fee Schedules Page
      </span>
    </div>
  );
};

const EditFeeSchedule = () => {
  const navigate = useNavigate();
  const { feeScheduleId } = useParams();
  const { updateRecord: updateFeeSchedule } = useUpdateFeeSchedule();
  const {
    data: feeScheduleResponse,
    isFetching,
    isError,
  } = useFindFeeSchedule(feeScheduleId);
  const feeSchedule = feeScheduleResponse?.data?.data || {};
  const initialValues = constructFeeScheduleInitialFormValues(feeSchedule);

  const handleSubmit = async (feeSchedule) => {
    const payload = constructFeeScheduleFormPayload(feeSchedule);
    try {
      await updateFeeSchedule(feeScheduleId, payload);
      navigate(`/fee-schedules/${feeScheduleId}`);
    } catch (error) {
      console.error("Error updating fee schedule: ", error);
    }
  };

  return (
    <>
      {isFetching && (
        <Spinner
          className="h-screen"
          center
          dataTestId="edit-fee-schedule-spinner"
        />
      )}
      {isError && <ErrorMessage navigate={navigate} />}
      {!isFetching && !isError && (
        <FeeScheduleForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
          feeScheduleId={feeScheduleId}
        />
      )}
    </>
  );
};

export default EditFeeSchedule;
