import React, { useState } from "react";
import PropTypes from "prop-types";
import { usePlans } from "api/core/planHooks";
import { usePayers } from "api/core/payerHooks";
import PaginatedTable from "components/Tables/PaginatedTable";
import { Button } from "@unite-us/app-components";
import { capitalize } from "lodash";

const FeeSchedulePlans = ({ feeSchedule }) => {
  const planIds = feeSchedule?.plans?.map((plan) => plan.id).toString();
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const {
    data: plansResponse,
    isFetching: isFetchingPlans,
    isError: isErrorPlans,
  } = usePlans({
    filters: { id: planIds },
    include: "",
    pageSize,
    pageNumber,
  });
  const { data: plans, paging } = plansResponse || {};
  const payerIds = Array.from(
    new Set(plans?.map((plan) => plan.payer?.id)),
  ).toString();

  const {
    data: payersResponse,
    isFetching: isFetchingPayers,
    isError: isErrorPayers,
  } = usePayers({
    filters: { id: payerIds },
    include: "",
  });
  const { data: payers } = payersResponse || {};

  const tableHeaders = [
    { label: "Plan Name", className: "w-1/4" },
    { label: "Plan Type", className: "w-1/4" },
    { label: "Payer Name", className: "w-1/4" },
    { label: "External ID", className: "w-1/4" },
  ];

  const tableBody = plans?.map((plan) => ({
    rowId: plan.id,
    rowData: [
      { data: plan.name },
      { data: capitalize(plan.plan_type) },
      { data: payers.filter((payer) => payer.id === plan.payer.id)[0]?.name },
      { data: plan.external_id },
    ],
  }));

  return (
    <>
      <div className="flex justify-between align-middle py-2 px-4 bg-medium-fill-grey border border-b-0 border-light-border-blue">
        <h1 className="text-xl content-center">Associated Plans</h1>
        <div className="flex flex-row space-x-4">
          <Button
            id="add-plan-button"
            icon={{ name: "Add", color: "text-blue", position: "left" }}
            className={
              "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case h-9"
            }
            label="Add Plan"
            data-testid="add-plan-button"
            disabled
          />
        </div>
      </div>
      <PaginatedTable
        body={tableBody}
        headers={tableHeaders}
        isFetching={isFetchingPlans || isFetchingPayers}
        isError={isErrorPlans || isErrorPayers}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        paging={paging}
        dataTestId="fee-schedule-plans-table"
        onRowClick={() => {}}
        emptyTableMessage='Click "Add Plan" to add Plans'
        errorMessage="Error fetching Plans."
        className={tableBody?.length !== 0 ? "!h-fit" : ""}
      />
    </>
  );
};

FeeSchedulePlans.propTypes = {
  feeSchedule: PropTypes.object.isRequired,
};

export default FeeSchedulePlans;
