export const stateBgColor = (state) => {
  switch (state) {
    case "active":
    case "published":
      return "bg-dark-green";
    case "inactive":
      return "bg-red";
    case "expired":
    case "unpublished":
      return "bg-yellow";
    case "archived":
    case "draft changes":
      return "bg-medium-fill-blue";
    default:
      return "";
  }
};

export const textColorForStyleProp = (state) => {
  switch (state) {
    case "active":
    case "published":
    case "inactive":
      return { color: "white" };
    case "archived":
    case "expired":
    case "unpublished":
    case "draft changes":
      return { color: "#182A44" }; // text-blue
    default:
      return {};
  }
};
