import React, { useEffect, useState } from "react";
import { Badge, Button } from "@unite-us/app-components";
import { useFeeSchedules } from "api/core/feeScheduleHooks";
import { capitalize } from "lodash";
import { stateBgColor, textColorForStyleProp } from "utils/stateBgColor";
import SearchBar from "components/SearchBar/SearchBar";
import PaginatedTable from "components/Tables/PaginatedTable";
import TablePageContainerLayout from "layouts/TablePageContainerLayout";
import useLocalStorage from "hooks/useLocalStorage";
import FeeSchedulesFilters from "./components/FeeSchedulesFilters";
import moment from "moment";
import { useNavigate } from "react-router";

const FeeSchedules = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useLocalStorage("feeSchedulesFilters", {});
  const [{ sortBy, sortDirection }, setSort] = useState({
    sortBy: "ends_at",
    sortDirection: "asc",
  });
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const {
    data: response,
    isFetching,
    isError,
  } = useFeeSchedules({
    filters,
    include: "",
    pageSize,
    pageNumber,
    sort: sortDirection === "asc" ? sortBy : `-${sortBy}`,
  });
  const { data: feeSchedules, paging } = response ?? {};

  useEffect(() => {
    setPageNumber(1);
  }, [filters]);

  const handleOnSubmitSearchBar = (data) => {
    setFilters({ ...filters, name: data?.search });
  };

  const tableHeaders = [
    { label: "Name", className: "w-1/4" },
    { label: "Status", className: "w-1/4" },
    { label: "Start Date", className: "w-1/4" },
    {
      label: "End Date",
      className: "w-1/4",
      isSortable: true,
      sortBy: sortBy,
      sortDirection: sortDirection,
      colName: "ends_at",
      setSort: setSort,
    },
  ];

  const tableBody = feeSchedules?.map((feeSchedule) => {
    const state = moment
      .utc(new Date())
      .startOf("day")
      .isAfter(moment.utc(feeSchedule.ends_at))
      ? "expired"
      : feeSchedule.state;

    return {
      rowId: feeSchedule.id,
      rowData: [
        { data: feeSchedule.name, className: "whitespace-nowrap capitalize" },
        {
          data: (
            <Badge
              className={`!leading-4 !text-sm px-4 pt ${stateBgColor(state)}`}
              text={capitalize(state)}
              color=""
              style={textColorForStyleProp(state)}
            />
          ),
        },
        { data: moment.utc(feeSchedule.starts_at).format("LL") },
        {
          data: `${moment.utc(feeSchedule.ends_at).format("LL")}${state === "expired" ? " (Expired)" : ""}`,
          className: state === "expired" ? "!text-red" : "",
        },
      ],
    };
  });

  return (
    <TablePageContainerLayout>
      <div data-testid="fee-schedules-header">
        <h1 className="w-12/12 flex-row pt-1 pb-2 font-bold text-brand-blue">
          Fee Schedules
        </h1>
        <div className="flex">
          <div className="w-9/12 flex flex-row relative">
            <div className="md:w-3/5">
              <SearchBar
                onSubmit={handleOnSubmitSearchBar}
                dataTestId="fee-schedules-search-bar"
                placeholder="Search by Name"
                delay={2000}
                initialValue={filters.name}
              />
            </div>
          </div>
          <div className="w-3/12 flex justify-end">
            <Button
              id="add-fee-schedule-button"
              icon={{ name: "Add", color: "text-blue", position: "left" }}
              className={
                "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case h-9"
              }
              label="Add Fee Schedule"
              onClick={() => navigate("/fee-schedules/new")}
              data-testid="add-fee-schedule-button"
            />
          </div>
        </div>
        <FeeSchedulesFilters filters={filters} setFilters={setFilters} />
      </div>
      <PaginatedTable
        headers={tableHeaders}
        body={tableBody}
        isFetching={isFetching}
        paging={paging}
        pageSize={pageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        dataTestId="fee-schedules-table"
        emptyTableMessage="No Fee Schedules Found"
        isError={isError}
        errorMessage="Error Fetching Fee Schedules"
        onRowClick={(rowId) => navigate(`/fee-schedules/${rowId}`)}
      />
    </TablePageContainerLayout>
  );
};

export default FeeSchedules;
