const statusColors = {
  successful_with_skips: "#D9F8D7",
  complete: "#FFD7C1",
  successful: "#D9F8D7",
  processing: "#FFD7C1",
  dead: "#FF9494",
};

const statusTextColors = {
  successful_with_skips: "#078B00",
  complete: "#DA6625",
  successful: "#078B00",
  processing: "#DA6625",
  dead: "#991B1B",
};

export const getStatusBadgeColor = (status) => {
  return statusColors[status.toLowerCase()];
};

export const getStatusBadgeClassname = (status) => {
  switch (status.toLowerCase()) {
    case "complete":
    case "processing":
      return "border-orange";
    case "successful":
    case "successful_with_skips":
      return "border-dark-green";
    case "dead":
      return "border-dark-red";
    default:
      return "bg-gray-100";
  }
};

export const getStatusBadgeStyle = (status) => {
  return {
    color: statusTextColors[status.toLowerCase()],
  };
};

export const getBatchJobStatus = (job, output) => {
  if (job.status) {
    return job.status === "failed" ? "Not Successful" : job.status;
  } else {
    return output?.user_invite_response?.success
      ? "Successful"
      : "Not Successful";
  }
};

export const getBatchStatus = (batch) => {
  switch (batch.status) {
    case "dead":
      return "Complete with errors";
    case "successful":
      return "Complete";
    case "successful_with_skips":
      return "Complete with skips";
    default:
      return "Pending";
  }
};
