export const getBatchAction = (batch) => {
  switch (batch.batch_type) {
    case "user_invites":
      return `Invite Users`;
    case "employee_updates":
      return `Update Users`;
    default:
      return "Unknown";
  }
};
