import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Icon, InputField } from "@unite-us/ui";

const SearchBar = ({
  className,
  inputClassName,
  placeholder,
  onSubmit,
  dataTestId,
  autoSubmit,
  delay,
  minAutoSearchLength,
  initialValue,
  inputStyle,
  showIcon,
}) => {
  const [value, setValue] = useState(initialValue || "");
  const timerRef = useRef(null);
  const prevValueRef = useRef(value);

  useEffect(() => {
    const prevValue = prevValueRef.current;

    if (prevValue !== undefined && prevValue !== value) {
      if (autoSubmit && delay > 0) {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => onSubmit({ search: value }), delay);
      } else if (
        autoSubmit &&
        !delay &&
        (value === "" || value.length >= minAutoSearchLength)
      ) {
        onSubmit({ search: value });
      }
    }

    prevValueRef.current = value;

    return () => clearTimeout(timerRef.current);
  }, [value, autoSubmit, delay, minAutoSearchLength, onSubmit]);

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit({ search: value });
  };

  return (
    <div className={className}>
      {showIcon && (
        <Icon
          className="absolute pt-2 pl-3"
          icon="IconSearch"
          size={30}
          color="#2C405A"
        />
      )}
      <form onSubmit={handleSubmit}>
        <InputField
          className={inputClassName}
          hideError
          style={{ input: { paddingLeft: "2.5rem", ...inputStyle } }}
          placeholder={placeholder}
          label="search"
          id="search"
          hideLabel
          dataTestId={dataTestId}
          value={value}
          onChange={(event) => setValue(event.target.value)}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              handleSubmit(event);
            }
          }}
        />
      </form>
    </div>
  );
};

SearchBar.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  dataTestId: PropTypes.string,
  autoSubmit: PropTypes.bool,
  delay: PropTypes.number,
  minAutoSearchLength: PropTypes.number,
  initialValue: PropTypes.string,
  inputStyle: PropTypes.object,
  showIcon: PropTypes.bool,
};

SearchBar.defaultProps = {
  className: "flex-grow",
  inputClassName: "md:w-4/5",
  placeholder: "Search by Keyword",
  dataTestId: "",
  autoSubmit: true,
  delay: 0,
  minAutoSearchLength: 2,
  initialValue: "",
  inputStyle: {},
  showIcon: true,
};

export default SearchBar;
