import React from "react";
import PropTypes from "prop-types";
import {
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
  TableHeader,
  Table,
  TableBody,
  TableSortableHeaderColumn,
} from "@unite-us/app-components";
import classNames from "classnames";
import Spinner from "components/Spinner/Spinner";
import EmptyTable from "./EmptyTable";

const CustomTable = ({
  className,
  body,
  headers,
  isFetching,
  emptyTableMessage,
  dataTestId,
  onRowClick,
  isError,
  errorMessage,
  showSpinnerOnEmptyFetch,
  isRowSelectable,
}) => {
  const isEmptyTable = (!isFetching && body.length === 0) || isError;
  const showBody = showSpinnerOnEmptyFetch
    ? body.length > 0
    : !isFetching && body.length > 0;
  const showSpinner = showSpinnerOnEmptyFetch
    ? isFetching && body.length === 0
    : isFetching;

  return (
    <div
      className={classNames(
        "flex flex-col min-w-full max-h-full h-full overflow-y-auto border-b rounded border-light-border-blue",
        className,
      )}
      data-testid={`${dataTestId}-container`}
    >
      <Table
        className={isEmptyTable || isFetching ? "!overflow-hidden" : "h-full"}
        onCellClick={(row, column) => {
          isRowSelectable
            ? column !== 0 && onRowClick(body[row].rowId)
            : onRowClick(body[row].rowId);
        }}
      >
        <TableHeader className="bg-gray-100 sticky top-0 z-10">
          <TableRow
            key="custom-table-headers"
            dataTestId={`${dataTestId}-header-row`}
            rowIndex={-1}
          >
            {headers.map((header, index) =>
              header.isSortable ? (
                <TableSortableHeaderColumn
                  key={`header-${index}`}
                  className={classNames(
                    "text-left text-sm font-medium uppercase tracking-wider",
                    header.className || "",
                  )}
                  style={header.style}
                  sortBy={header.sortBy}
                  sortDirection={header.sortDirection}
                  setSort={header.setSort}
                  colName={header.colName}
                  label={header.label}
                  columnIndex={index}
                >
                  {header.label}
                </TableSortableHeaderColumn>
              ) : (
                <TableHeaderColumn
                  key={`header-${index}`}
                  className={classNames(
                    "text-left text-sm font-medium uppercase tracking-wider",
                    header.className || "",
                  )}
                  style={header.style}
                  columnIndex={index}
                >
                  {header.label}
                </TableHeaderColumn>
              ),
            )}
          </TableRow>
        </TableHeader>
        <TableBody>
          {showBody &&
            body.map(({ rowId, rowClassName, rowData }, rowIndex) => (
              <TableRow
                key={`row-${rowIndex}`}
                className={classNames(
                  "hover:bg-gray-50 cursor-pointer",
                  rowClassName || "",
                )}
                dataTestId={`${dataTestId}-row-${rowId}`}
                rowIndex={rowIndex}
              >
                {rowData.map((cell, cellIndex) => (
                  <TableRowColumn
                    key={`cell-${rowIndex}-${cellIndex}`}
                    className={classNames(
                      "text-left text-sm font-medium",
                      cell.className || "",
                    )}
                    style={cell.style}
                  >
                    {cell.data}
                  </TableRowColumn>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {isEmptyTable && (
        <EmptyTable
          className={"border border-t-0 border-solid border-light-border-blue"}
          message={isError ? errorMessage : emptyTableMessage}
        />
      )}
      {showSpinner && (
        <div className="h-full border border-t-0 border-solid border-light-border-blue">
          <Spinner dataTestId={`${dataTestId}-spinner`} center />
        </div>
      )}
    </div>
  );
};

CustomTable.propTypes = {
  className: PropTypes.string,
  body: PropTypes.arrayOf(
    PropTypes.shape({
      rowId: PropTypes.string.isRequired,
      rowClassName: PropTypes.string,
      rowData: PropTypes.arrayOf(
        PropTypes.shape({
          data: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
          className: PropTypes.string,
          style: PropTypes.object,
          onCellClick: PropTypes.func,
        }),
      ).isRequired,
    }),
  ),
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      className: PropTypes.string,
      style: PropTypes.object,
      isSortable: PropTypes.bool,
      sortBy: PropTypes.string,
      sortDircetion: PropTypes.oneOf(["asc", "desc"]),
      setSort: PropTypes.func,
      colName: PropTypes.string,
    }),
  ),
  isFetching: PropTypes.bool,
  emptyTableMessage: PropTypes.string,
  dataTestId: PropTypes.string,
  isRowSelectable: PropTypes.bool,
  onRowClick: PropTypes.func,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  showSpinnerOnEmptyFetch: PropTypes.bool,
};

CustomTable.defaultProps = {
  className: undefined,
  body: [],
  headers: [],
  isFetching: false,
  emptyTableMessage: "No data found",
  dataTestId: "custom-table",
  isRowSelectable: false,
  onRowClick: () => {},
  isError: false,
  errorMessage: "Error fetching data.",
  showSpinnerOnEmptyFetch: false,
};

export default CustomTable;
