import React, { useState } from "react";
import { useParams } from "react-router";
import { useFindFeeSchedule } from "api/core/feeScheduleHooks";
import Spinner from "components/Spinner/Spinner";
import FeeScheduleHeader from "./components/FeeScheduleHeader";
import FeeScheduleProgramsTable from "./components/FeeScheduleProgramsTable";
import FeeSchedulePlans from "./Plans/FeeSchedulePlans";

const FeeSchedule = () => {
  const [location, setLocation] = useState("fee-schedule-programs");
  const { feeScheduleId } = useParams();
  const {
    data: feeScheduleResponse,
    isFetching,
    isError,
  } = useFindFeeSchedule(feeScheduleId);
  const feeSchedule = feeScheduleResponse?.data?.data || {};
  const shouldRenderTabComponent = !isFetching && !isError;
  const crumbs = [
    { label: "Fee Schedules", href: "/fee-schedules" },
    { label: feeSchedule.name },
  ];

  return (
    <div className="h-full flex flex-grow-0 flex-col">
      <FeeScheduleHeader
        handleTabChange={setLocation}
        location={location}
        feeSchedule={feeSchedule}
        crumbs={crumbs}
      />
      <div className="px-20 py-8">
        {isFetching && <Spinner center />}
        {isError && <div>There was an error fetching fee schedule</div>}
        {shouldRenderTabComponent && location === "fee-schedule-programs" && (
          <FeeScheduleProgramsTable feeScheduleId={feeScheduleId} />
        )}
        {shouldRenderTabComponent && location === "plans" && (
          <FeeSchedulePlans feeSchedule={feeSchedule} />
        )}
      </div>
    </div>
  );
};

export default FeeSchedule;
