import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import PaginatedTable from "components/Tables/PaginatedTable";
import { useNetworks } from "api/core/networkHooks";
import SearchBar from "components/SearchBar/SearchBar";
import { trimAndNormalizeSpaces } from "utils/string";

const NetworkTable = ({ setSelectedNetwork }) => {
  const [networkSearch, setNetworkSearch] = useState("");
  const [selectedNetworkId, setSelectedNetworkId] = useState("");
  const [pageSize, setPageSize] = useState(25);
  const [pageNumber, setPageNumber] = useState(1);
  const { data: networksResponse, isFetching } = useNetworks({
    filters: { name: networkSearch, state: "active" },
    pageSize,
    pageNumber,
  });
  const { data: networks, paging } = networksResponse;

  useEffect(() => {
    setPageNumber(1);
  }, [networkSearch]);

  useEffect(() => {
    const filteredNetworks =
      networks?.filter((network) => network.id === selectedNetworkId) || [];
    if (filteredNetworks.length > 0) {
      const [networkObject] = filteredNetworks.map(({ id, name }) => ({
        id,
        name,
      }));
      setSelectedNetwork(networkObject);
    }
  }, [selectedNetworkId, networks, setSelectedNetwork]);

  const tableHeaders = [
    { label: "Name", className: "w-2/3" },
    { label: "Network ID", className: "w-1/3" },
  ];

  const tableBody = networks?.map((network) => {
    return {
      rowId: network.id,
      rowClassName: selectedNetworkId === network.id ? "bg-pale-grey" : "",
      rowData: [
        {
          data: network.name,
          className: "whitespace-nowrap capitalize",
        },
        { data: network.id, className: "whitespace-nowrap capitalize" },
      ],
    };
  });

  const handleOnSubmitSearchBar = (data) => {
    const searchTerm = data.search ? trimAndNormalizeSpaces(data.search) : " ";
    setNetworkSearch(searchTerm || " ");
  };

  return (
    <>
      <SearchBar
        onSubmit={handleOnSubmitSearchBar}
        dataTestId="network-search-bar"
        placeholder="Enter the Network Name..."
        inputClassName="w-full"
      />
      <PaginatedTable
        body={tableBody}
        headers={tableHeaders}
        isFetching={isFetching}
        emptyTableMessage="No Networks Found"
        pageSize={pageSize}
        pageNumber={pageNumber}
        paging={paging}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        dataTestId="network-table"
        onRowClick={setSelectedNetworkId}
      />
    </>
  );
};

NetworkTable.propTypes = {
  setSelectedNetwork: PropTypes.func.isRequired,
};

export default NetworkTable;
