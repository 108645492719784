import React, { useEffect, useState } from "react";
import { Badge, Button } from "@unite-us/app-components";
import { usePayers } from "api/core/payerHooks";
import { capitalize } from "lodash";
import { stateBgColor, textColorForStyleProp } from "utils/stateBgColor";
import SearchBar from "components/SearchBar/SearchBar";
import PaginatedTable from "components/Tables/PaginatedTable";
import { useNavigate } from "react-router";
import TablePageContainerLayout from "layouts/TablePageContainerLayout";
import useLocalStorage from "hooks/useLocalStorage";

export const Payers = () => {
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useLocalStorage("payersSearchTerm", "");
  const { data: response, isFetching } = usePayers({
    filters: { name: searchTerm },
    include: "",
    pageSize,
    pageNumber,
  });
  const navigate = useNavigate();

  const { data: payers, paging } = response;

  const addPayer = () => {
    navigate("/payers/new");
  };

  const viewPayer = (payerId) => navigate(`/payers/${payerId}`);

  useEffect(() => {
    setPageNumber(1);
  }, [searchTerm]);

  const handleOnSubmitSearchBar = (data) => {
    setSearchTerm(data?.search);
  };

  const tableHeaders = [
    { label: "Name", className: "w-1/4" },
    { label: "Status", className: "w-1/4" },
    { label: "External ID", className: "w-1/4" },
    { label: "Payer WQ Interactive", className: "w-1/4" },
  ];
  const tableBody = payers.map((payer) => ({
    rowId: payer.id,
    rowData: [
      { data: payer.name, className: "whitespace-nowrap capitalize" },
      {
        data: (
          <Badge
            className={`!leading-4 !text-sm px-4 pt ${stateBgColor(payer.state)}`}
            text={capitalize(payer.state)}
            color=""
            style={textColorForStyleProp(payer.state)}
          />
        ),
      },
      { data: payer.external_id },
      { data: capitalize(payer.payer_wq_interactive) },
    ],
  }));

  return (
    <TablePageContainerLayout>
      <div data-testid="payers-header">
        <h1 className="w-12/12 flex-row pt-1 pb-2 font-bold text-brand-blue">
          Payers
        </h1>
        <div className="flex pb-2">
          <div className="w-9/12 flex flex-row relative">
            <div className="md:w-3/5">
              <SearchBar
                onSubmit={handleOnSubmitSearchBar}
                dataTestId="payers-search-bar"
                placeholder="Search by Name"
                delay={2000}
                initialValue={searchTerm}
              />
            </div>
          </div>
          <div className="w-3/12 flex justify-end">
            <Button
              id="add-payer-button"
              icon={{ name: "Add", color: "text-blue", position: "left" }}
              className={
                "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case h-9"
              }
              label="Create New Payer"
              onClick={() => addPayer()}
              data-testid="create-payer-btn"
            />
          </div>
        </div>
      </div>
      <PaginatedTable
        body={tableBody}
        headers={tableHeaders}
        isFetching={isFetching}
        emptyTableMessage="No Payers Found"
        pageSize={pageSize}
        pageNumber={pageNumber}
        paging={paging}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        dataTestId="payers-table"
        onRowClick={viewPayer}
      />
    </TablePageContainerLayout>
  );
};
