import React from "react";
import PropTypes from "prop-types";
import { FilterSingle } from "@unite-us/client-utils";

const FeeSchedulesFilters = ({ filters, setFilters }) => {
  const filtersClassName = "w-1/5 pr-2 pt-1";

  const handleStatusChange = (target) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      state: target.value,
    }));
  };

  return (
    <div className="flex justify-start flex-wrap w-full pb-2 [&_button>svg]:-translate-y-1.5">
      <FilterSingle
        key="fee-schedule-status-filter-key"
        uniqIdPrefx="fee-schedule-status-filter"
        name="fee-schedule-status"
        className={filtersClassName}
        dataTestId="fee-schedule-status-filter"
        value={filters.state || "active"}
        options={[
          { label: "Active", value: "active" },
          { label: "Inactive", value: "inactive" },
          { label: "Archived", value: "archived" },
        ]}
        onFilterChange={handleStatusChange}
        filterKey="feeScheduleStatusFilter"
      />
    </div>
  );
};

FeeSchedulesFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default FeeSchedulesFilters;
